<template>
  <div class="repaymentCode">
    <mt-header :title="$t('repayment')">
      <router-link to slot="left">
        <mt-button @click="$router.back(-1)">
          <img src="@/assets/back1.png" height="20" width="20" slot="icon">
          {{ $t('back') }}
        </mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <div class="content">
      <div class="content-header">
        <div class="content-amt">$ {{ amount | formatMoney }}</div>
        <div class="content-text">Cuenta de repago</div>
      </div>
      <div class="content-box">
        <mt-cell :title="code">
          <mt-button @click="onCopy" class="copy" size="small" plain>{{ $t('copy') }}</mt-button>
        </mt-cell>
        <img id="barcode" />
      </div>
    </div>
  </div>
</template>

<script>
import callPhone from '@/components/callPhone.vue'
import { payBarCode, payClabe } from '../../utils/api'
import JsBarcode from 'jsbarcode'
export default {
  name: 'RepaymentCode',
  components: {
    callPhone
  },
  data() {
    return {
      code: ''
    }
  },
  computed: {
    applyId() {
      return this.$route.query.applyId
    },
    type() {
      return this.$route.query.type
    },
    amount() {
      return this.$route.query.amount
    },
  },
  mounted() {
    if (this.type == 'OXXO') {
      this.getBarCode(); // 获取条形码
    } else {
      this.getClabe(); // 获取还款码
    }
  },
  methods: {
    async getBarCode() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      this.$NProgress.start()
      await this.$axios({
        method: "POST",
        url: payBarCode,
        params: {
          applyId: this.applyId,
          amount: this.amount
        }
      }).then((e) => {
        if (e.status.code == "000") {
          this.code = e.body.barcode;
          JsBarcode('#barcode', e.body.barcodeUrl, {
            format: 'CODE39',
            lineColor: '#000',
            background: '#EBEEF5',
            width: 2,
            height: 40,
            displayValue: false
          })
        }
      }).catch(() => {})
      this.$indicator.close();
      this.$NProgress.done()
    },
    async getClabe() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      this.$NProgress.start()
      await this.$axios({
        method: "POST",
        url: payClabe,
        params: {
          applyId: this.applyId,
          amount: this.amount
        }
      }).then((e) => {
        if (e.status.code == "000") {
          this.code = e.body.clabe;
        }
      }).catch(() => {})
      this.$indicator.close();
      this.$NProgress.done()
    },
    onCopy() {
      let transfer = document.createElement('input');
      document.body.appendChild(transfer);
      transfer.readonly = 'readonly';
      transfer.value = this.code;
      transfer.focus();
      transfer.select();
      if (document.execCommand('copy')) {
          document.execCommand('copy');
      }
      transfer.blur();
      this.$toast(this.$t('copySuccess'));
      document.body.removeChild(transfer);
    }
  }
}
</script>

<style lang="scss" scoped>
.repaymentCode {
  background: url("../../assets/bg1.png");
  background-size: 100% auto;
  min-height: 100vh;
  .content {
    padding: 10px;
    margin: 10px;
    border-radius: 6px;
    .content-header{
      margin-top: 60px;
    }
    .content-amt{
      font-size: 30px;
      font-weight: bold;
      text-align: center;
      color: #FFFFFF;
    }
    .content-text{
      margin-top: 10px;
      font-size: 16px;
      text-align: center;
      color: #FFFFFF;
    }
    .content-box{
      margin-top: 30px;
      background: #F9F6FF;
      font-weight: bold;
      border-radius: 20px;
    }
    /deep/ .mint-cell{
      background-color: transparent;
      border-bottom: 1px solid #FFFFFF;
      min-height: 60px;
    }
    /deep/ .mint-cell-wrapper{
      background: transparent;
    }
    .copy{
      background: #855EF4;
      border-radius: 20px;
      color: #FFFFFF;
    }
    .tips {
      padding: 10px;
      font-size: 14px;
      color: #FFFFFF;
    }
    #barcode {
      width: 100%;
      margin-top: 10px;
    }
  }
}
</style>
